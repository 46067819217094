import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

// let currentSlide = 0;

const copyright = document.querySelector(".year");
copyright.textContent = new Date().getFullYear();

const mobileSidebarLinks = document.querySelectorAll(".main-nav-link");

const btnNavEl = document.querySelector(".btn-mobile-nav");
const headerEl = document.querySelector(".header");
btnNavEl.addEventListener("click", function () {
  headerEl.classList.toggle("nav-open");
  document.body.classList.toggle("no-scroll");
});

mobileSidebarLinks.forEach(function (link) {
  link.addEventListener("click", function (event) {
    document.body.classList.remove("no-scroll");
    headerEl.classList.remove("nav-open");

    const href = link.getAttribute("href");

    const targetElement = document.querySelector(href);

    if (targetElement) {
      const headerHeight = 79;

      const scrollPosition = targetElement.offsetTop - headerHeight;

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }

    event.preventDefault();
  });
});

const sectionHeroEl = document.querySelector(".section-main");

const obs = new IntersectionObserver(
  function (entries) {
    const ent = entries[0];
    if (ent.isIntersecting === false) {
      document.body.classList.add("sticky");
    }

    if (ent.isIntersecting === true) {
      document.body.classList.remove("sticky");
    }
  },
  {
    root: null,
    threshold: 0,
    rootMargin: "-80px",
  }
);
obs.observe(sectionHeroEl);

document.addEventListener("DOMContentLoaded", function () {
  const form = document.getElementById("contact-form");
  var thankYouMessage = document.getElementById("thank-you-message");

  form.addEventListener("submit", function (event) {
    event.preventDefault();

    const formData = new FormData(form);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "./php/process_form.php", true);

    // Set up the request headers
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // Form submitted successfully. Hide the form and show the "Thank you" message.
          form.style.display = "none";
          thankYouMessage.style.display = "block";
        } else {
          // Handle errors if the form submission fails.
          alert("Form submission failed. Please try again.");
        }
      }
    };

    // Send the form data
    xhr.send(formData);
  });
});
